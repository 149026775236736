import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ListPage from 'pages/_templates/ListPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { SvgIcon } from '@mui/material';
import { CheckCircleOutline, DeleteOutline } from '@mui/icons-material';
import { trackEvent } from 'utils/mixpanel';
import { getRouterQueryParams } from 'utils/router-query-params';
import { setFilter, setSortBy } from 'store/modules/filterDesvios/actions';

import { ReactComponent as icMotoristas } from 'images/icons/sidebar/drivers.svg';
import { ReactComponent as icResponsavel } from 'images/icons/sidebar/seguir.svg';

import ExcelModal from 'components/ExcelModalNew';
import AlertModal from 'components/AlertModal';
import { Modal as SelectMotoristaModal } from 'components/Inputs/SelectMotorista/templates/modal';
import ResponsibleModal from './components/ResponsibleModal';
import FinishModal from './components/FinishModal';
import DeleteModal from './components/DeleteModal';
import Deslocamento from './components/Deslocamento';
import FiltersGlobal from 'components/FiltersGlobal';
import { ButtonApply } from './styled';
import { getIndicator, indicadorAnexoExclusao } from 'hooks/useParams';

import { ReactComponent as IconAdd } from '../../images/icons/components/add-btn.svg';

import {
  requestCards,
  requestDesvios,
  aprovarDesvios,
  atribuirResponsavel,
  finalizarDesvios,
  requestCount,
  getDeslocamentos,
  tratarDesvios,
  excluirDesvio,
  salvarDesvio,
} from './services';
import { columns, columnsEmbarcador, fields, statusTabs } from './constants';
import DesvioManualModal from './components/DesvioManualModal';
import { usePlans } from 'hooks/usePlans';
import { generateFileName } from 'utils/generateFileName';

import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { subDays } from 'date-fns';
import { useTheme } from 'styled-components';

const today = new Date();
const initialDate = subDays(today, 30);
const finalDate = today;

const Desvios = () => {
  // Redux e hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpLogistico, isProvider, hasTorrePlus, hasTorre } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const selects = useSelector(state => state.selects);
  const userLevel = user.nivel;
  const [lastUpdated, setLastUpdated] = useState(null);

  const empresasFilter =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.desvios;
  });

  const filterDesvios = useSelector(state => state.filterDesvios);
  const [query, setQuery] = useState({
    sortBy: { id: 'data_desvio', order: 'DESC', desc: true },
    pageIndex: 0,
    pageSize: 20,
    initialDate,
    finalDate,
    ...filtersPersist,
    status:
      filterDesvios?.status == 'ABERTO' &&
      isProvider &&
      !(hasTorrePlus || isOpLogistico)
        ? 'PENDENTE'
        : filterDesvios?.status,
  });
  const [loadingTab, setLoadingTab] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedInfractions, setSelectedInfractions] = useState([]);
  const [alertSelectd, setAlertSelected] = useState([]);

  const [openDesvioManual, setOpenDesvioManual] = useState(false);
  const [responsibleModal, setResponsibleModal] = useState(null);
  const [motoristaModal, setMotoristaModal] = useState({ open: false });
  const [deleteModal, setDeleteModal] = useState(null);
  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const [openExcelModal, setOpenExcelModal] = useState(false);

  const [deslocamentosRisco, setDeslocamentosRisco] = useState([]);

  const {
    responsaveis,
    filiais,
    clients,
    tecnologias,
    criticidade,
    origin,
    desviosTiposPadrao,
  } = useFetchMultipleWithCache();

  const [excelFields, setExcelFields] = useState(
    fields.filter(f => {
      if (isProvider && (hasTorre || hasTorrePlus))
        return f.hasTorre || [1, 2].includes(f.provider);
      if (isProvider) return !f.hasTorre && [1, 2].includes(f.provider);
      return [0, 2].includes(f.provider);
    }),
  );
  const initial = useRef(true);

  const getAnexoObrigatorio = desvio => {
    const indicadorAnexo = indicadorAnexoExclusao[desvio.criticidade];
    if (!indicadorAnexo) return;
    const anexo = getIndicator(
      indicadorAnexo,
      desvio.id_distribuidora,
      desvio.id_empresa,
      selects.params,
    );
    return anexo;
  };

  // const handleRequestExcel = async (evaluateQuery, sendUsingEmail) => {
  //   setLoadingExcel(true);
  //   const res = await requestExcel({
  //     excelFields,
  //     ...query,
  //     status: filterDesvios.status,
  //     file_name: generateFileName(),
  //     evaluateQuery,
  //     sendUsingEmail,
  //   });

  //   if (res.link) {
  //     downloadFromLink(res.link);
  //     toast.success(res.message);
  //   } else if (res.message) toast.error(res.message);

  //   setLoadingExcel(false);
  //   setOpenExcelModal(false);
  // };

  const {
    refetch: fetchData,
    isFetching,
    data: resData,
  } = useQuery(['desvios', query], () => query && requestDesvios(query), {
    refetchOnWindowFocus: false,
    refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
    onSuccess: () => {
      setLoadingLines([]);
      setLoadingTab(false);
      resetTable && setResetTable(false);
    },
    onSettled: () => {
      const currentTime = new Date();
      setLastUpdated(currentTime);
    },
  });

  // ********************  Deslocamento *****************************
  const { refetch: fetchDeslocamento, isFetching: loadingDeslocamento } =
    useQuery(
      ['deslocamentos', 'ABERTO'],
      () => getDeslocamentos({ tipo: 'ABERTO' }),
      {
        refetchOnWindowFocus: false,
        refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
        onSuccess: resDataDeslocamento => {
          if (resDataDeslocamento?.data)
            setDeslocamentosRisco(
              resDataDeslocamento?.data
                .filter(item => item.risco !== 'BAIXO')
                .slice(0, 3),
            );
        },
        enabled: hasTorre || hasTorrePlus,
      },
    );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-desvios',
      {
        ...filtersPersist,
        ...filterDesvios,
      },
    ],
    () =>
      requestCards({
        ...filtersPersist,
        ...filterDesvios,
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
      enabled: !initial.current,
    },
  );

  // Atualiza totalizador apenas quando muda filtros ou cards
  const {
    refetch: fetchCount,
    isFetching: loadingCount,
    data: resCount,
  } = useQuery(
    [
      'count-desvios',
      {
        ...query,
      },
    ],
    () =>
      requestCount({
        ...query,
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
      staleTime: 0,
      enabled: !initial.current,
    },
  );

  // Altera tab inicial em caso de url com parametro
  const status = getRouterQueryParams({ location: useLocation }).get('status');
  const card = getRouterQueryParams({ location: useLocation }).get('card');

  useEffect(() => {
    statusTabs.map(s => s.value).includes(status) &&
      dispatch(
        setFilter({
          status,
        }),
      );
    [
      'proximo_vencimento',
      'criticidade_alta',
      'exclusao_revisao',
      'desvios_analise',
    ].includes(card) && setSelectedCard(card);
  }, [statusTabs, status, card]);

  // Altera tab inicial em caso de Distribuidora
  useEffect(() => {
    if (
      isProvider &&
      !(hasTorrePlus || isOpLogistico) &&
      filterDesvios.status === 'ABERTO'
    )
      dispatch(
        setFilter({
          status: 'PENDENTE',
        }),
      );
  }, []);

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filterDesvios.status]);

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações
  const handleAtribuirResponsavel = async (data, approve) => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);

    const res = approve
      ? await aprovarDesvios(data)
      : await atribuirResponsavel(data);
    if (res.data?.success) {
      fetchCards();
      fetchCount();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setResponsibleModal(null);
  };

  const handleDeletar = async (ids, data) => {
    setLoadingModal(true);
    const res = await excluirDesvio(ids, data);
    if (res.data?.success) {
      fetchCards();
      fetchCount();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
    }
    setLoadingModal(false);
    setDeleteModal(null);
  };

  const verifyIdsFinish = ids => {
    const valid = resData?.data?.data
      ?.filter(item => ids.includes(item.id))
      .every(
        item => item.responsavel_nome && item.plano_acao && item.data_previsao,
      );

    if (valid) {
      setIdsFinishModal(ids);
    } else {
      toast.warning(
        'Não é possível finalizar desvios sem usuário responsável, plano ação ou data de previsão.',
      );
    }
  };

  const handleFinalizar = async ids => {
    setLoadingModal(true);
    setLoadingLines(ids || []);

    const res = await finalizarDesvios({ ids });
    if (res.data?.success) {
      fetchCards();
      fetchCount();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  const handleAtribuirMotorista = async (id_motorista, ids) => {
    setLoadingModal(true);
    setLoadingLines(ids || []);

    const payload = { id_motorista };
    const res = await salvarDesvio(ids, payload);
    if (res.data?.success) {
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
    }
    setLoadingModal(false);
    setMotoristaModal({ open: false });
  };

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    setQuery({ ...query, status: newValue });
    dispatch(
      setFilter({
        status: newValue,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  // Atualiza as colunas de acordo com o status selecionado
  const handleColumns = () => {
    let _columns = isProvider && !isOpLogistico ? columnsEmbarcador : columns;
    if (isProvider && hasTorrePlus)
      _columns[1] = {
        header: 'Motorista/Transp',
        id: 'motorista_nome',
        type: 'string',
        tooltip: true,
        sort: true,
        conditional: [
          {
            condition: (_, item) => !!item.id_motorista,
            style: theme => ({
              color: theme?.palette?.words.title.natural,
            }),
          },
          {
            condition: (_, item) => !item.id_motorista,
            style: theme => ({
              color: theme?.palette?.words.subtitle.light,
            }),
          },
        ],
        subRow: {
          value: (_, item) => item?.empresa_nome,
        },
      };
    const col = [..._columns];

    if (filterDesvios.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvios.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];
    const data = resData?.data?.data || [];
    const desviosProprios =
      !isProvider ||
      hasTorrePlus ||
      !data
        .filter(item => selectedInfractions.includes(item.id))
        .some(item => item.id_empresa);

    if (desviosProprios && userLevel < 4) {
      switch (filterDesvios.status) {
        case 'ABERTO':
          // Confere se seleção tem a mesma filial
          const filialIds = [];
          const distIds = [];
          const emRevisao = [];
          const comMotorista = [];
          let anexoObrigatorio = false;
          for (const desvio of data) {
            const filialId = desvio.id_filial;
            const distId = desvio.id_distribuidora;
            const revisao = desvio.aprovado_distribuidora !== null;
            const motorista = !!desvio.id_motorista;
            if (selectedInfractions.includes(desvio.id)) {
              if (!filialIds.includes(filialId)) filialIds.push(filialId);
              if (!distIds.includes(distId)) distIds.push(distId);
              if (revisao) emRevisao.push(desvio.id);
              if (motorista) comMotorista.push(desvio.id);
              const anexo = getAnexoObrigatorio(desvio);
              if (anexo) anexoObrigatorio = true;
            }
          }
          const mesmaFilial = filialIds.length < 2;
          const mesmoCliente = distIds.length < 2;
          const semMotorista = !comMotorista.length;

          let newAlertSelected = [];
          if (!mesmaFilial)
            newAlertSelected.push(
              'Desvios de filiais diferentes não executam a ações em massa: aprovar',
            );
          if (userLevel === 1) {
            if (!mesmoCliente)
              newAlertSelected.push(
                'Desvios de clientes diferentes não executam a ação em massa: excluir',
              );
            if (emRevisao.length)
              newAlertSelected.push(
                'Desvios em revisão não executam a ação em massa: excluir',
              );
          }
          if (newAlertSelected.length != alertSelectd.length)
            setAlertSelected(newAlertSelected);

          actions = [
            semMotorista && {
              icon: <SvgIcon fontSize="small" component={icMotoristas} />,
              title: 'Atribuir motorista',
              function: ids => {
                trackEvent(user, 'ATRIBUIR MOTORISTA NOS DESVIOS');
                setMotoristaModal({ open: true, data: ids });
              },
            },
            mesmaFilial && {
              icon: <SvgIcon fontSize="small" component={icResponsavel} />,
              title: 'Atribuir responsável',
              function: ids => {
                trackEvent(user, 'ATRIBUIR RESPONSAVEL NOS DESVIOS');
                setResponsibleModal({ approve: false, data: ids });
              },
            },
            mesmaFilial && {
              icon: <CheckCircleOutline fontSize="small" />,
              title: 'Aprovar',
              function: ids => {
                trackEvent(user, 'APROVAR TODOS OS DESVIOS');
                setResponsibleModal({ approve: true, data: ids });
              },
            },
            mesmoCliente &&
              userLevel === 1 &&
              !emRevisao.length && {
                icon: <DeleteOutline fontSize="small" />,
                title: 'Excluir',
                variant: 'attention',
                function: ids => {
                  trackEvent(user, 'EXCLUIR TODOS OS DESVIOS');
                  setDeleteModal({ data: ids, anexoObrigatorio });
                },
              },
          ].filter(item => !!item);
          break;

        case 'PENDENTE':
          actions = [
            {
              icon: <CheckCircleOutline fontSize="small" />,
              title: 'Finalizar',
              function: ids => {
                trackEvent(user, 'FINALIZAR TODOS OS DESVIOS');
                verifyIdsFinish(ids);
              },
            },
          ];
          break;

        default:
          break;
      }
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [
      {
        title: 'Abrir em nova guia',
        function: handleOpenNewTab,
      },
    ];
    const data = resData?.data?.data || [];
    if ((!isProvider || isOpLogistico || hasTorrePlus) && userLevel < 4)
      switch (filterDesvios.status) {
        case 'ABERTO':
          actions = [
            ...actions,
            {
              title: 'Aprovar',
              function: id => {
                trackEvent(user, 'APROVAR DESVIOS');
                setResponsibleModal({ approve: true, data: [id] });
              },
            },
            {
              title: 'Excluir',
              function: id => {
                trackEvent(user, 'EXCLUIR DESVIO');
                let anexoObrigatorio = false;
                for (const desvio of data) {
                  if (id === desvio.id) {
                    const anexo = getAnexoObrigatorio(desvio);
                    if (anexo) anexoObrigatorio = true;
                  }
                }
                setDeleteModal({ data: [id], anexoObrigatorio });
              },
            },
          ];
          break;

        case 'PENDENTE':
          actions = [
            ...actions,
            {
              title: 'Finalizar',
              function: id => {
                trackEvent(user, 'FINALIZAR DESVIOS');
                verifyIdsFinish([id]);
              },
            },
          ];
          break;

        default:
          break;
      }
    return actions.filter(Boolean);
  };

  const getIndex = id => {
    const _data = [...(resData?.data?.data ?? [])];
    dispatch(setSortBy(query.sortBy));

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvios/${id}?index=${getIndex(id)}`);
  };

  const handleOpenDetails = id => {
    const url = `/desvios/${id}?index=${getIndex(id)}`;
    navigate(url);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: filterDesvios.status,
    items: statusTabs.filter(
      item =>
        item.value !== 'ABERTO' || !isProvider || isOpLogistico || hasTorrePlus,
    ),
    onChange: handleStatusTab,
    disabled: loadingCards || isFetching,
  };

  const navigateToDeslocamentos = () => {
    navigate(`/desvios/deslocamentos`);
  };

  useEffect(() => {
    setResetTable(true);
  }, [filtersPersist, selectedCard]);

  const handleTratarDesvio = async data => {
    const res = await tratarDesvios(data);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchDeslocamento();
    }
  };

  useEffect(() => {
    if (alertSelectd.length) {
      for (const alertMsg of alertSelectd) {
        toast.warning(alertMsg);
      }
    }
  }, [alertSelectd]);

  // filtros utilizaods no acesso Provider
  const providerFilters = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresasFilter || [],
    },
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    {
      filterName: 'filial_veiculo',
      label: 'Filial Veículo',
      options: filiais || [],
    },
    {
      filterName: 'tecnologia',
      label: 'Tecnologias',
      options: tecnologias.data || [],
    },
    {
      filterName: 'categorias',
      label: 'Origem',
      options: origin || [],
    },
    {
      filterName: 'criticidade',
      label: 'Criticidades',
      options: criticidade.data || [],
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao?.data || [],
    },
    {
      filterName: 'responsavel',
      label: 'Responsáveis',
      options: responsaveis?.data || [],
    },
  ];

  if (isProvider && hasTorrePlus) {
    providerFilters.push({
      filterName: 'motoristaNaoDefinido',
      label: 'Motoristas',
      multiple: false,
      options: [
        { label: 'Identificado', value: 0 },
        { label: 'Não identificado', value: 1 },
      ],
    });
  }

  // Filtros utilizados no acesso Transportador
  const transporterFilters = [
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    {
      filterName: 'filial_veiculo',
      label: 'Filial Veículo',
      options: filiais || [],
    },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
    {
      filterName: 'tecnologia',
      label: 'Tecnologias',
      options: tecnologias.data || [],
    },
    {
      filterName: 'categorias',
      label: 'Origem',
      options: origin || [],
    },
    {
      filterName: 'criticidade',
      label: 'Criticidades',
      options: criticidade.data || [],
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao?.data || [],
    },
    {
      filterName: 'responsavel',
      label: 'Responsáveis',
      options: responsaveis?.data || [],
    },
    {
      filterName: 'motoristaNaoDefinido',
      label: 'Motoristas',
      multiple: false,
      options: [
        { label: 'Identificado', value: 0 },
        { label: 'Não identificado', value: 1 },
      ],
    },
  ];

  const renderFilters = () => {
    return (
      <div style={{ marginTop: 16, marginBottom: 10 }}>
        <FiltersGlobal
          showHours
          persistDate
          customComponent={
            <>
              {(!isProvider || isOpLogistico || hasTorrePlus) &&
                userLevel < 4 && (
                  <ButtonApply
                    startIcon={<IconAdd />}
                    onClick={() => {
                      setOpenDesvioManual(true);
                    }}
                  >
                    Adicionar desvio manual
                  </ButtonApply>
                )}
            </>
          }
          refetch={fetchData}
          isFetching={isFetching}
          handleExport={() => setOpenExcelModal(true)}
          data={isProvider ? providerFilters : transporterFilters}
        />
      </div>
    );
  };

  return (
    <>
      {(loadingDeslocamento || deslocamentosRisco?.length > 0) && (
        <>
          <div
            style={{
              color: '#4B5166',
              fontWeight: '900',
              fontSize: 24,
              marginBottom: 15,
            }}
          >
            Esses deslocamentos precisam da sua atenção!
          </div>
          <Deslocamento
            data={deslocamentosRisco}
            onSave={handleTratarDesvio}
            loading={loadingDeslocamento}
          />

          <div
            onClick={() => navigateToDeslocamentos()}
            style={{
              textAlign: 'end',
              marginBottom: 30,
              color: ' #ff8040',
              textDecoration: 'underline',
              fontSize: 16,
              cursor: 'pointer',
            }}
          >
            Ver todos os deslocamentos
          </div>
        </>
      )}

      <ListPage
        title="Lista de desvios"
        headerTabs={headerTabs}
        // cards={resCards?.map(card => ({ ...card, disabled: isFetching })) || []}
        // selectedCard={selectedCard}
        // handleClickCard={handleClickCard}
        lastUpdated={hasTorre || hasTorrePlus ? lastUpdated : null}
        customComponent={renderFilters()}
        loadingCards={loadingCards}
        tableProps={{
          data: resData?.data?.data || [],
          columns: handleColumns(),
          setSelectedRows:
            ['ABERTO', 'PENDENTE'].includes(filterDesvios.status) &&
            (!isProvider || isOpLogistico || hasTorrePlus)
              ? setSelectedInfractions
              : null,
          loading: isFetching || loadingCount,
          loadingCounter: loadingCount,
          pageCount: resCount?.data?.total || 0,
          visualizedKey: 'visto',
          local: false,
          bulk: getBulkActions(),
          actions: getActions(),
          reset: resetTable,
          onClickRow: handleOpenDetails,
          loadingSelection: loadingLines,
          setQuery: q =>
            setQuery({
              ...query,
              ...filtersPersist,
              ...q,
              card: selectedCard,
            }),
          searchEvent: search =>
            trackEvent(user, 'Busca Desvios de Direção', null, search),
          sortBy: { id: 'data_desvio', order: 'DESC' },
          placeholder: `Buscar por ID, ID frota, placa ou ${
            isProvider && !(isOpLogistico || hasTorrePlus)
              ? 'empresa'
              : 'motorista'
          }`,
          empty: {
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          },
        }}
      />
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={excelFields}
          query={{
            ...query,
            initialDate: new Date(
              new Date(query.initialDate).setHours(
                new Date(query.initialDate).getHours() - 3,
              ),
            ).toISOString(),
            finalDate: new Date(
              new Date(query.finalDate).setHours(
                new Date(query.finalDate).getHours() - 3,
              ),
            ).toISOString(),
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route="/excel/desvios"
          filtersPersis={filtersPersist}
          collumns={isProvider ? providerFilters : transporterFilters}
        />
      )}

      {responsibleModal && (
        <ResponsibleModal
          approve={responsibleModal?.approve}
          handleConfirm={handleAtribuirResponsavel}
          ids={responsibleModal?.data}
          handleClose={() => setResponsibleModal(null)}
          loading={loadingModal}
        />
      )}

      {deleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          handleClose={() => setDeleteModal(null)}
          ids={deleteModal?.data}
          loading={loadingModal}
          anexoObrigatorio={deleteModal?.anexoObrigatorio}
        />
      )}

      {idsFinishModal && (
        <FinishModal
          handleConfirm={() => handleFinalizar(idsFinishModal)}
          ids={idsFinishModal}
          handleClose={() => setIdsFinishModal(null)}
          loading={loadingModal}
        />
      )}
      {openDesvioManual && (
        <DesvioManualModal
          open={openDesvioManual}
          handleClose={() => setOpenDesvioManual(false)}
          refetchDesvios={fetchData}
        />
      )}
      {motoristaModal?.open && (
        <SelectMotoristaModal
          open={!!motoristaModal?.open}
          handleClose={() => setMotoristaModal({ open: false })}
          handleConfirm={(id, motorista) => {
            setMotoristaModal(state => ({
              ...state,
              open: false,
              confirm: true,
              motorista,
            }));
          }}
        />
      )}
      {motoristaModal?.confirm && (
        <AlertModal
          open={!!motoristaModal.confirm}
          handleClose={() => {
            setMotoristaModal(state => ({
              ...state,
              open: true,
              confirm: false,
            }));
          }}
          title="Essa ação é irreversível!"
          subtitle={
            <p>
              Deseja mesmo atribuir o motorista{' '}
              <span>
                {String(motoristaModal?.motorista?.nome || '').toLowerCase()}
              </span>
              ?<br />
              Essa ação não poderá ser desfeita.
            </p>
          }
          buttonText="Continuar"
          onClick={() =>
            handleAtribuirMotorista(
              motoristaModal.motorista.value,
              motoristaModal.data,
            )
          }
        />
      )}
    </>
  );
};

export default Desvios;
