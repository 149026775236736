import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styled';

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// componentes
import { GraphBar } from './components/GraphBar';
import { GraphBarForaDoPrazo } from './components/GraphBarForaDoPrazo';

import PieGraph from './components/PieGraph';
import BarChartVertical from './components/GraficoBarVertical';
import Tabs from 'components/Tabs';
import TabsNew from 'components/FiltersGlobal/Tabs';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import GraficoBarraVertical from './components/GraficoBarraVertical';
import { GraficoHitorico } from './components/GraficoHistorico';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import FiltersGlobal from 'components/FiltersGlobal';
import { History } from 'components/Graphs/History';
import { NaoIdentificados } from './NaoIdentificados';

import {
  getHistoricoTratativas,
  getJustified,
  getEquipe,
  getMovimentacoes,
  getDesviosTratadosForaPrazo,
} from './services';

import {
  legends_client,
  legends_empresa,
  tabs_client,
  tabs_empresa,
  StyledBadge,
  formatDataGraphPie,
  calculateTotalsAndPercentages,
} from './constants';

const TrativasDesvios = () => {
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();

  const filterTratativa = useSelector(
    state => state?.filter['tratativa-desvios'],
  );

  const selects = useSelector(state => state.selects);

  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState({
    initialDate: new Date(),
    finalDate: new Date(),
  });

  const [currentTab, setCurrentTab] = useState('CRITICIDADE');
  const [historyGraph, setHistoryGraph] = useState([]);
  const [historyGraphMeta, setHistoryGraphMeta] = useState([]);
  const [historyGraphLoading, setHistoryGraphLoading] = useState(false);

  const [historyFilterType, setHistoryFiterType] = useState('TRATADOSNOPRAZO');
  const [graphJustied, setGraphJustied] = useState(['TRATADOSNOPRAZO']);
  const [graphMovimentacao, setGraphMovimentacao] = useState([]);
  const [graphMoviPie, setGraphMovPie] = useState([]);
  const [desviosTratados, setDesviosTratados] = useState([]);
  const [metaDesviosTratados, setMetaDesviosTratados] = useState(null);
  const [desviosTratadosforaPrazo, setDesviosTratadosForaPrazo] = useState([]);

  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingMovimentacoes, setLoadingMovimentacoes] = useState(false);
  const [loadingjustify, setLoadingJustify] = useState(false);
  const [loadingDtfp, setLoadingDtfp] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [cardOne, setCardOne] = useState([]);
  const [cardTwo, setCardTwo] = useState([]);

  function splitArray(array) {
    const len = array.length;
    const mid = Math.ceil(len / 2);

    const firstHalf = array.slice(0, mid);
    const secondHalf = array.slice(mid);

    return [firstHalf, secondHalf];
  }

  const shouldDisplay = isProvider ? hasTorrePlus : hasTorre || hasTorrePlus;

  const initial = new Date(filterDate.initialDate);
  const final = new Date(filterDate.finalDate);

  const differenceInDays = (final - initial) / (1000 * 60 * 60 * 24);

  const formatDate = isoDate => {
    if (isoDate) {
      const timeZone = 'America/Sao_Paulo';
      const zonedDate = utcToZonedTime(isoDate, timeZone);
      const formattedDate = format(zonedDate, 'HH:mm');
      return `Ontem às ${formattedDate}`;
    }
    return ' - ';
  };

  const fetchHistory = async () => {
    setHistoryGraphLoading(true);
    const res = await getHistoricoTratativas({
      ...filterDate,
      ...filterTratativa,
      type: historyFilterType,
    });

    const data = res?.data?.data;
    const meta = res?.data?.meta;
    setHistoryGraphMeta(meta);

    setHistoryGraph(data);
    setHistoryGraphLoading(false);
  };

  const fetchJustified = async () => {
    setLoadingJustify(true);
    const res = await getJustified();
    setGraphJustied(res?.data || []);
    setLoadingJustify(false);
  };

  const fetchEquipe = async () => {
    setLoadingCard(true);
    const res = await getEquipe();
    const [firstHalf, secondHalf] = splitArray(res?.data || []);
    setCardOne(firstHalf);
    setCardTwo(secondHalf);
    setLoadingCard(false);
  };

  const fetchMovimentacoes = async () => {
    setLoadingMovimentacoes(true);
    const res = await getMovimentacoes({
      ...filterDate,
      ...filterTratativa,
      type: currentTab,
    });

    const porcentage = calculateTotalsAndPercentages(res?.data || []);
    if (currentTab === 'CRITICIDADE') {
      setDesviosTratados(porcentage);
      setMetaDesviosTratados(res?.meta ?? 0);
    }

    setGraphMovimentacao(res?.data || []);
    if (res?.data) {
      const dataFromated = formatDataGraphPie(
        res.data,
        isProvider && !hasTorrePlus,
      );

      setGraphMovPie(dataFromated);
    }
    setLoadingMovimentacoes(false);
  };

  const fetchDesviosTratadosForaPrazo = async () => {
    setLoadingDtfp(true);
    const res = await getDesviosTratadosForaPrazo({
      ...filterDate,
      ...filterTratativa,
    });
    let data = res?.data || [];

    setDesviosTratadosForaPrazo(data);
    setLoadingDtfp(false);
  };

  useEffect(() => {
    fetchHistory();
  }, [filterDate, historyFilterType]);

  useEffect(() => {
    fetchMovimentacoes();
  }, [currentTab, filterDate, companies]);

  useEffect(() => {
    fetchDesviosTratadosForaPrazo();
  }, [filterDate]);

  useEffect(() => {
    fetchHistory();
    fetchJustified();
    fetchEquipe();
    fetchMovimentacoes();
    fetchDesviosTratadosForaPrazo();
  }, []);

  const handleNavigate = url => navigate(url);

  const legends =
    hasTorrePlus || !isProvider ? legends_client : legends_empresa;

  const filtersProviders = [
    {
      filterName: 'companies',
      label: 'Empresas',
      options:
        selects.empresas.map(item => {
          return { value: item.id, label: item.value };
        }) || [],
    },
  ];

  const filtersTransporter = [
    {
      filterName: 'filials',
      label: 'Filial',
      options: selects?.filials.map(item => {
        return {
          value: item.id,
          label: item.nome,
        };
      }),
    },
    {
      filterName: 'clients',
      label: 'Cliente',
      options: selects?.clients.map(item => {
        return {
          value: item.id,
          label: item.nome,
        };
      }),
    },
  ];

  return (
    <Container>
      <FiltersGlobal
        handleFilters={setFilterDate}
        data={isProvider ? filtersProviders : filtersTransporter}
        customComponent={
          <div className="container-title">
            <div className="title-main">Estatísticas de tratativas</div>
          </div>
        }
        hideExportButton
        hideRefleshButton
        persistDate
      />

      <div className="container-header">
        <div className="first-graph">
          <div className="title">Desvios tratados no prazo</div>
          <BarChartVertical data={desviosTratados} meta={metaDesviosTratados} />
        </div>
        <div className="second-graph">
          <div className="top">
            <div className="top-title">Movimentações no período</div>
            <div className="container-tabs">
              <Tabs
                onChange={(e, val) => setCurrentTab(val)}
                value={currentTab}
                items={isProvider ? tabs_empresa : tabs_client}
              />
            </div>
          </div>
          <div className="middle">
            <div style={{ width: 300 }}>
              <PieGraph data={graphMoviPie} loading={loadingMovimentacoes} />
            </div>
            <div className="container-movi">
              <GraphBar
                data={graphMovimentacao}
                loading={loadingMovimentacoes}
                condition={isProvider && !hasTorrePlus}
              />
            </div>
          </div>

          <div className="footer">
            {legends.map(item => (
              <>
                <div
                  className="point"
                  style={{ backgroundColor: item.color }}
                />
                <div className="text">{item?.text || ''}</div>
              </>
            ))}
          </div>
        </div>
      </div>

      {!hasTorrePlus && isProvider && (
        <div className="graph-prazo">
          <div className="graph-prazo-title">
            Desvios concluídos fora do prazo
          </div>
          <GraphBarForaDoPrazo
            data={desviosTratadosforaPrazo}
            loading={loadingDtfp}
          />
        </div>
      )}
      {shouldDisplay && (
        <>
          <div className="minha-equipe-title">Minha equipe</div>
          <div className="content">
            {!loadingCard
              ? cardOne.map((card, index) => (
                  <div>
                    <div
                      className="card"
                      key={`${index}-one`}
                      onClick={() => handleNavigate(`/equipe/${card?.id}`)}
                    >
                      <div className="card-content">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              badgeColor={
                                card?.status === 'ATIVO' ? '#1BC5BD' : '#f64e60'
                              }
                              badgeBackgroundColor={
                                card?.status === 'ATIVO' ? '#1BC5BD' : '#f64e60'
                              }
                              variant="dot"
                            >
                              <Avatar
                                alt={cardTwo[index]?.nome}
                                src={cardTwo[index]?.foto}
                                sx={{
                                  width: 47,
                                  height: 47,
                                  boxShadow: '0px 3px 6px #00003229',
                                  backgroundColor: '#939aab',
                                }}
                              />
                            </StyledBadge>
                          </Stack>
                          <div className="name">{card.nome}</div>
                        </div>
                        <div>
                          <ArrowForwardIosIcon sx={{ color: '#4B5166' }} />
                        </div>
                      </div>
                      <div className="footer">
                        <div className="first-title">
                          Tempo médio de tratativa:{' '}
                          <span style={{ color: '#4B5166', fontWeight: 900 }}>
                            {card?.tempo_medio
                              ? `${card?.tempo_medio} minutos`
                              : ' - '}
                          </span>
                        </div>
                        <div className="secound-title">
                          Última tratativa:{' '}
                          <span style={{ color: '#939aab', fontWeight: 900 }}>
                            {formatDate(cardTwo[index]?.ultima_tratativa)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card"
                      key={`${index}-two`}
                      onClick={() =>
                        handleNavigate(`/equipe/${cardTwo[index]?.id}`)
                      }
                    >
                      <div className="card-content">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <StyledBadge
                              badgeColor={
                                cardTwo[index]?.status === 'ATIVO'
                                  ? '#1BC5BD'
                                  : '#f64e60'
                              }
                              badgeBackgroundColor={
                                cardTwo[index]?.status === 'ATIVO'
                                  ? '#1BC5BD'
                                  : '#f64e60'
                              }
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              variant="dot"
                            >
                              <Avatar
                                alt={cardTwo[index]?.nome}
                                src={cardTwo[index]?.foto}
                                sx={{
                                  width: 47,
                                  height: 47,
                                  boxShadow: '0px 3px 6px #00003229',
                                  backgroundColor: '#939aab',
                                }}
                              />
                            </StyledBadge>
                          </Stack>
                          <div className="name">
                            {cardTwo[index]?.nome || ' - '}
                          </div>
                        </div>
                        <div>
                          <ArrowForwardIosIcon sx={{ color: '#4B5166' }} />
                        </div>
                      </div>
                      <div className="footer">
                        <div className="first-title">
                          Tempo médio de tratativa:{' '}
                          <span style={{ color: '#4B5166', fontWeight: 900 }}>
                            {cardTwo[index]?.tempo_medio
                              ? `${cardTwo[index]?.tempo_medio} minutos`
                              : ' - '}
                          </span>
                        </div>
                        <div className="secound-title">
                          Última tratativa:{' '}
                          <span style={{ color: '#939aab', fontWeight: 900 }}>
                            {formatDate(cardTwo[index]?.ultima_tratativa)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : Array.from({ length: 10 }, () =>
                  Math.floor(Math.random() * 100),
                ).map((card, index) => (
                  <div key={index}>
                    <Skeleton
                      variant="rounded"
                      width={302}
                      height={139}
                      style={{ marginLeft: 20 }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={302}
                      height={139}
                      style={{ marginTop: 20, marginLeft: 20 }}
                    />
                  </div>
                ))}
          </div>
        </>
      )}
      <div className="see-more" onClick={() => handleNavigate(`/equipe`)}>
        Ver todos
      </div>
      <div className="card-bottom">
        <div style={{ width: hasTorrePlus || !isProvider ? '70%' : '100%' }}>
          <History
            titulo="Histórico de tratativas"
            data={historyGraph.map(item => {
              return {
                valor: item.average_minutes,
                period: item.month_year,
                meta: historyGraphMeta,
              };
            })}
            lineColor="meta"
            yAxisPosfix={historyFilterType === 'TRATADOSNOPRAZO' ? '%' : 'min'}
            series={['valor']}
            loading={historyGraphLoading}
            leftHeaderComponent={
              <TabsNew
                tabs={[
                  {
                    value: 'TRATADOSNOPRAZO',
                    label: 'Desvios tratados no prazo',
                  },
                  { value: 'TEMPOMEDIO', label: 'Tempo médio de tratativas' },
                ]}
                handleApplay={val => {
                  setHistoryFiterType(val);
                }}
              />
            }
            initialDate={(filterTratativa || filterDate)?.initialDate}
            finalDate={(filterTratativa || filterDate)?.finalDate}
          />
        </div>
        {(hasTorrePlus || !isProvider) && (
          <div style={{ marginLeft: 40 }}>
            <GraficoBarraVertical
              data={graphJustied}
              dataKey1="motivo_exclusao"
              title="Justificativas de exclusão"
              isLoading={loadingjustify}
            />
          </div>
        )}
      </div>
      {(hasTorre || hasTorrePlus) && <NaoIdentificados />}
    </Container>
  );
};

export default TrativasDesvios;
