export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Criada por',
    value: 'aluno',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial',
    selected: true,
    default: true,
  },
  {
    label: 'Ocorrência',
    value: 'ocorrencia',
    selected: true,
    default: true,
  },
  {
    label: 'ID Resposta',
    value: 'id_resposta',
    selected: true,
    default: true,
  },
  {
    label: 'ID Formulário',
    value: 'id_formulario',
    selected: true,
    default: true,
  },
  {
    label: 'Formulário',
    value: 'formulario',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Data de criação',
    value: 'data_evento',
    selected: true,
    default: true,
  },
  {
    label: 'Data vencimento',
    value: 'data_vencimento',
    selected: true,
    default: true,
  },
  {
    label: 'Data encerramento',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
];

export const violacoesFields = [
  {
    label: 'Violação',
    value: 'descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Decaimento (dias)',
    value: 'decaimento_tempo',
    selected: true,
    default: true,
  },
  {
    label: 'Prazo (dias)',
    value: 'prazo',
    selected: true,
    default: true,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: false,
    default: false,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = true;
    return i;
  });
};
