import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Icon } from 'components/IconsOnisys';

// Styles
import * as S from './styled';

export const Atalhos = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pageMap, currentPlan } = useSelector(state => state.pageMap);
  const { user } = useSelector(state => state.auth?.user);

  const paginasArr = pageMap
    ?.filter(plugin => plugin.id_do_ambiente === currentPlan && plugin.active)
    ?.map(plugin => plugin.paginas);

  const paginas = _.flatten(paginasArr)?.sort(
    (a, b) => a.posicao_sidebar - b.posicao_sidebar,
  );

  // Garante que o item aparecerá apenas uma vez na sidebar
  const ids = [];
  const secoes = [];
  const sidebarContent = paginas?.filter(item => {
    if (
      item.is_sidebar &&
      !ids.includes(item.id) &&
      !secoes.includes(item.secao) &&
      item.pagina !== '/' &&
      item.restricao_nivel >= user.nivel
    ) {
      ids.push(item.id);
      secoes.push(item.secao);
      return true;
    }
    return false;
  });

  return (
    <S.Container>
      {sidebarContent.map((i, k) => (
        <div
          className="card"
          key={k}
          onClick={() => {
            navigate(i.pagina);
          }}
        >
          <Icon
            style={{
              height: '32px',
              color: theme.palette.words.subtitle.natural,
            }}
            name={i.icone}
          />
          <div className="text">
            {i?.descricao.length > 20
              ? `${i?.descricao.slice(0, 24)}...`
              : i?.descricao}
          </div>
        </div>
      ))}
    </S.Container>
  );
};
