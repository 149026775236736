import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

// styles
import * as S from './styled';
import GraphSkeleton from '../Graph/GraphSkeleton';
import { useTheme } from 'styled-components';

const CustomTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    const key = payload[0]?.payload?.key; // Obtém a chave completa do payload (ex: "19/11/24")
    const labelDate = label.split('/')[0]; // Obtém a parte "curta" da data (ex: "19")

    return (
      <div
        style={{
          backgroundColor: theme?.palette?.brand?.primary?.background,
          padding: '10px',
          borderRadius: '4px',
          border: `1px solid ${theme?.palette?.system?.divider}`,
          transform: 'translate(0, -67%)',
        }}
      >
        {/* Exibe a chave (key) no tooltip */}
        <p>{key}</p>

        {/* Exibe o valor associado ao key */}
        <p
          style={{ color: payload[0]?.color || '#000' }}
        >{`${payload[0]?.name} : ${payload[0]?.value}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const HistoryPoints = ({
  data = [],
  print = false,
  isLoading = false,
  interval = 'auto',
}) => {
  return (
    <>
      {!isLoading ? (
        <S.Container print={print}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              height={300}
              data={data}
              margin={{ top: 20, right: 40, bottom: 0, left: 0 }}
            >
              {data.length === 0 && (
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={22}
                  fill="#999"
                >
                  Nenhum dado encontrado, tente usar outros filtros.
                </text>
              )}
              <XAxis
                opacity={0.5}
                padding={{ left: 0, right: 0 }}
                dataKey="name"
                height={70}
                tick={<CustomTick />}
                tickLine={false}
                interval={interval}
              />

              {data.length !== 0 && (
                <YAxis
                  opacity={0.5}
                  padding={{ left: 0, right: 0 }}
                  tickLine={false}
                  minTickGap={-20}
                />
              )}
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ strokeDasharray: '3 3', stroke: '#939AAB' }}
              />

              <Line
                type="linear"
                dataKey="x"
                name="Valor"
                stroke={data.length > 0 ? '#0C12F2' : '#eee'}
                dot={{ r: 0 }}
                activeDot={{ r: 0 }}
              />

              <Line
                type="linear"
                dataKey="meta"
                name="Meta"
                stroke="#f64e60"
                strokeDasharray={['10 10']}
                dot={{ r: 0 }}
                activeDot={{ r: 0 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </S.Container>
      ) : (
        <GraphSkeleton />
      )}
    </>
  );
};
