const palette = {
  brand: {
    primary: {
      light: '#939AAB',
      natural: '#494F65',
      dark: '#373f54',
      background: '#F9FBFD',
    },
    secondary: {
      light: '#FFDDD3',
      natural: '#FF8040',
      dark: '#FF3D03',
      background: '#F1F5D5',
    },
  },
  primary: {
    light: '#939AAB',
    main: '#494F65',
    dark: '#373f54',
  },
  secondary: {
    light: '#FFDDD3',
    main: '#FF8040',
    dark: '#FF3D03',
  },
  semantics: {
    feedback: {
      attention: {
        light: '#FFCCD1',
        natural: '#F64E60',
        dark: '#F64E60',
      },
      warning: {
        light: '#FFE7BB',
        natural: '#FFA801',
        dark: '#E3B40B',
      },
      success: {
        light: '#EAFFFE',
        natural: '#1BC5BD',
        dark: '#006d68',
      },
      information: {
        light: '#B2B3FF',
        natural: '#0C12F2',
        dark: '#0c12f2',
      },
      unknown: {
        light: '#F2F2F2',
        natural: '#CAD4E4',
        dark: '#4F4F4F',
      },
    },
  },
  words: {
    text: {
      light: '#939AAB',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
    title: {
      light: '#C3C3C3',
      natural: '#4B5166',
      dark: '#3d465d',
      contrast: '#FFFFFF',
    },
    subtitle: {
      light: '#939AAB',
      natural: '#656E8C',
      dark: '#000032',
      contrast: '#EFEFEF',
    },
    caption: {
      light: '#C3C3C3',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
    label: {
      light: '#C3C3C3',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
    button: {
      light: '#C3C3C3',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
    link: {
      light: '#C3C3C3',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
    placeholder: {
      light: '#C3C3C3',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
    disabled: {
      light: '#C3C3C3',
      natural: '#424449',
      dark: '#000032',
      contrast: '#FFFFFF',
    },
  },
  system: {
    transparent: 'transparent',
    background: '#F9FBFD',
    border: '#EDF2F9',
    overlay: '#FFFFFF',
    highlight: '#FFFFFF',
    disabled: '#F2F2F2',
    divider: '#939AAB4D',
    scrollbar: '#424449',
    backdrop: '#22222222',
    shadow: '#424449',
    white: '#FFFFFF',
    black: '#424449',
    light: '#EDF2F9',
    natural: '#939AAB',
    dark: '#424449',
  },
};
export default palette;
