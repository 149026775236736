// React
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

// PDF Images
import Volante from 'images/volante.png';
import LogoOnisys from 'images/onisys.png';

// PDF Components

// General Components
import Icon from 'components/Icons';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Default';

// Material UI
import Grid from '@mui/material/Grid';

// Utils
import { format } from 'date-fns';
import { formatNewHour, ptMonths, formatNewHourClean } from 'utils/dates';

// import moment from 'moment'

// Services
import FormEvaluation from 'components/Form';
import { useTheme } from 'styled-components';
import {
  requestAnswersEvaluation,
  updateLogs,
  requestEvaluation,
  requestTypesQuestions,
} from '../services';
import Imagens from './imagens';
import Desvio from './desvios';
import * as S from './styled';

const ModelPdf = () => {
  const theme = useTheme();
  // Navigate
  const navigate = useNavigate();

  // Url Params
  const { id } = useParams();

  // General States
  const contentRef = useRef(null);
  const promiseResolveRef = useRef(null);
  const [col1, setCol1] = useState([]);
  const [col2, setCol2] = useState([]);
  const [col3, setCol3] = useState([]);
  const [answers, setAnswers] = useState(null);
  const [desvios, setDesvios] = useState(null);
  const [desvForms, setDesvForms] = useState(null);
  const [sections, setSections] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avaliacao, setAvaliacao] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [totalImages, setTotalImages] = useState(0);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);

  // -----------------------REQUEST-------------------------//
  const fetch = async () => {
    const ans = await requestAnswersEvaluation(id);
    const eva = await requestEvaluation(id);
    const types = await requestTypesQuestions();
    setSections(types.data);
    setAvaliacao(eva.data.evaluation);
    setAnswers(ans.data.respostas);
    setDesvForms(ans.data.desvios);
    setDesvios(eva.data.infractions);
    setDesvForms();
    setValuesData(eva.data.evaluation, ans.data.respostas);
  };

  const getLoadingText = () => {
    if (!isReadyToPrint) {
      const total = totalImages;
      const loaded = loadedImages.size;
      const percentage = total > 0 ? Math.round((loaded / total) * 100) : 0;
      return `Carregando imagens (${loaded}/${total}) - ${percentage}%`;
    }
    return isPrinting ? 'Preparando PDF...' : 'Download PDF';
  };

  const clearPageCache = async () => {
    try {
      // Limpar cache do navegador
      if ('caches' in window) {
        const cacheKeys = await caches.keys();
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
      }

      // Limpar cache de service worker
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.all(
          registrations.map(registration => registration.unregister()),
        );
      }

      // Forçar recarregamento de recursos
      const resources = performance.getEntriesByType('resource');
      resources.forEach(resource => {
        const url = resource.name;
        if (url.includes(window.location.origin)) {
          const link = document.createElement('link');
          link.rel = 'prefetch';
          link.href = `${url}?cache=${Date.now()}`;
          document.head.appendChild(link);
        }
      });
    } catch (error) {
      console.error('Erro ao limpar cache:', error);
    }
  };

  // Função para verificar todas as imagens no documento
  const getAllImages = () => {
    if (!contentRef.current) return [];
    return Array.from(contentRef.current.getElementsByTagName('img'));
  };

  // Função para limpar o cache de uma imagem
  const clearImageCache = src => {
    if (!src) return '';

    // If it's a Google Storage URL
    if (src.includes('storage.googleapis.com')) {
      // Parse the URL
      const url = new URL(src);

      // Add or update cache buster
      url.searchParams.set('cache', Date.now());

      return url.toString();
    }

    // For other URLs
    return `${src}${src.includes('?') ? '&' : '?'}cache=${Date.now()}`;
  };

  // Função para forçar o recarregamento de uma imagem
  const forceImageReload = img => {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = clearImageCache(img.src);
    });
  };

  const isBase64Image = src => {
    return Boolean(
      src && typeof src === 'string' && src.startsWith('data:image'),
    );
  };

  // Função para pré-carregar uma única imagem
  const preloadImage = src => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.referrerPolicy = 'no-referrer';

      img.onload = () => {
        handleImageLoad(src);
        resolve(src);
      };

      img.onerror = error => {
        console.error(`Error loading image: ${src}`, error);
        reject(error);
      };

      // Add cache buster to URL
      img.src = `${src}${src.includes('?') ? '&' : '?'}cache=${Date.now()}`;
    });
  };
  // Função para monitorar o carregamento de imagens
  const handleImageLoad = src => {
    setLoadedImages(prev => {
      const newSet = new Set(prev);
      newSet.add(src);
      return newSet;
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  // Use este useEffect para configurar o monitoramento inicial
  useEffect(() => {
    if (avaliacao?.imagens) {
      const images = getAllImages();
      setTotalImages(images.length);

      // Pré-carregar todas as imagens
      const preloadAll = async () => {
        try {
          const imagePromises = images.map(img => {
            // Verificar se a imagem já está carregada
            if (img.complete) {
              handleImageLoad(img.src);
              return Promise.resolve(img.src);
            }
            return preloadImage(img.src);
          });

          await Promise.all(imagePromises);
          setIsReadyToPrint(true);
        } catch (error) {
          console.error('Erro ao pré-carregar imagens:', error);
          // Mesmo com erro, permitir a impressão após um tempo
          setTimeout(() => setIsReadyToPrint(true), 5000);
        }
      };

      preloadAll();

      // Adicionar listeners para todas as imagens
      images.forEach(img => {
        img.addEventListener('load', () => handleImageLoad(img.src));
        img.addEventListener('error', () => handleImageLoad(img.src)); // Considerar erro como carregado
      });

      // Cleanup
      return () => {
        images.forEach(img => {
          img.removeEventListener('load', () => handleImageLoad(img.src));
          img.removeEventListener('error', () => handleImageLoad(img.src));
        });
      };
    }
  }, [avaliacao]);

  // ------------------PRINT CONTROLLER---------------------//
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    onBeforeGetContent: async () => {
      return new Promise(resolve => {
        const preparePrint = async () => {
          setIsPrinting(true);
          promiseResolveRef.current = resolve;

          try {
            // Limpar cache apenas das imagens que não são base64
            setLoadedImages(new Set());
            setIsReadyToPrint(false);

            const images = getAllImages();
            const nonBase64Images = images.filter(
              img => !isBase64Image(img.src),
            );
            setTotalImages(nonBase64Images.length);

            // Recarregar apenas imagens não-base64
            const reloadPromises = nonBase64Images.map(img => {
              return forceImageReload(img)
                .then(() => handleImageLoad(img.src))
                .catch(error => {
                  console.error('Erro ao recarregar imagem:', error);
                  handleImageLoad(img.src);
                });
            });

            await Promise.all(reloadPromises);

            // Limpar cache do navegador para outros recursos
            if ('caches' in window) {
              try {
                await caches.keys().then(names => {
                  return Promise.all(names.map(name => caches.delete(name)));
                });
              } catch (err) {
                console.error('Erro ao limpar cache:', err);
              }
            }

            setIsReadyToPrint(true);
            resolve();
          } catch (error) {
            console.error('Erro ao preparar impressão:', error);
            setTimeout(() => {
              setIsReadyToPrint(true);
              resolve();
            }, 5000);
          }
        };

        preparePrint();
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
      handleIsPrinted();
    },
  });

  const handleIsPrinted = async () => {
    if (avaliacao && avaliacao.status_impressao !== 'IMPRESSO') {
      const body = { isPrinted: 'IMPRESSO' };
      await updateLogs(id, body);
    }
  };
  // -----------------FORMAT PAGE---------------------------//
  const columns1 = [
    {
      label: 'Transportadora',
    },
    {
      label: 'Semana',
    },
    {
      label: 'Cliente',
    },
    {
      label: 'Placa Cavalo',
    },
    {
      label: 'Data da Avaliação',
    },
    {
      label: 'Local/Trecho',
    },
  ];

  const columns2 = [
    {
      label: 'Filial',
    },
    {
      label: 'Mês',
    },
    {
      label: 'Horas Avaliadas',
    },
    {
      label: 'Placa Semi Reboque',
    },
    {
      label: 'Disponibilizada em',
    },
  ];

  const cards = [
    {
      label: 'N de Itens Avaliados',
    },
    {
      label: 'N de Itens Atendidos',
    },
    {
      label: 'Percentual de Atendimento',
    },
    {
      label: 'Data de Finalização',
    },
    {
      label: 'Data de Feedback',
    },
    {
      label: 'Percentual de Preenchimento',
    },
  ];

  const t1 = 'Antes do início da viagem';
  const t2 = 'Respeita os procedimentos da empresa';
  const t3 = 'Procedimentos da direção segura';
  const t4 = 'Utilização de câmeras embarcadas';
  const t5 = 'Após a viagem';
  const t6 = 'Perguntas adicionais (não obrigatórias)';

  const header = [t1, t2, t3, t4, t5, t6];

  // Set all values in Page
  const setValuesData = (data, answ) => {
    columns1.forEach(r => {
      if (r.label == 'Transportadora') {
        r.value = `${data.filial.empresa.nome}`;
      } else if (r.label == 'Semana') {
        r.value = `${data.semana}`;
      } else if (r.label == 'Cliente') {
        r.value = `${data.filial.empresa.nome}`;
      } else if (r.label == 'Placa Cavalo') {
        r.value = `${data.caminhao ? data.caminhao.placa : ''}`;
      } else if (r.label == 'Data da Avaliação') {
        r.value = `${
          data.data_avaliacao ? formatNewHourClean(data.data_avaliacao) : ''
        }`;
      } else if (r.label == 'Local/Trecho') {
        r.value = `${data.local ? data.local : ''}`;
      }
    });

    columns2.forEach(r => {
      if (r.label == 'Filial') {
        r.value = `${data.filial.nome}`;
      } else if (r.label == 'Mês') {
        r.value = `${ptMonths[new Date(data.data_avaliacao).getMonth()]}`;
      } else if (r.label == 'Horas Avaliadas') {
        r.value = `${data.horas || 1}h`;
      } else if (r.label == 'Placa Semi Reboque') {
        r.value = `${data.placa_reboque ? data.placa_reboque : ''}`;
      } else if (r.label == 'Disponibilizada em') {
        r.value = `${
          data.data_pendente
            ? formatNewHourClean(data.data_pendente)
            : formatNewHour(data.createdAt)
        }`;
      }
    });

    cards.forEach(r => {
      if (r.label == 'N de Itens Avaliados') {
        const avaliados = answ?.filter(item => item.resposta !== 'N/A') || [];
        r.value = `${avaliados.length}`;
      } else if (r.label == 'N de Itens Atendidos') {
        const avaliados = answ?.filter(item => item.resposta === 'SIM') || {};
        r.value = `${avaliados.length}`;
      } else if (r.label == 'Percentual de Atendimento') {
        const hasYes = answ?.filter(item => item.resposta === 'SIM') || [];
        const hasNo = answ?.filter(item => item.resposta === 'NAO') || [];
        r.value = `${(
          (hasYes.length * 100) / (hasYes.length + hasNo.length) || 0
        ).toFixed(2)}%`;
      } else if (r.label == 'Data de Finalização') {
        r.value = `${
          data.data_finalizacao ? formatNewHour(data.data_finalizacao) : ''
        }`;
      } else if (r.label == 'Data de Feedback') {
        r.value = `${
          data.data_feedback ? formatNewHour(data.data_feedback) : ''
        }`;
      } else if (r.label == 'Percentual de Preenchimento') {
        const doed = answ?.filter(item => item?.resposta !== 'N/A') || [];
        r.value = `${((doed.length * 100) / answ.length || 0).toFixed(2)}%`;
      }
    });

    setCol1(columns1);
    setCol2(columns2);
    setCol3(cards);
  };

  const renderAnswers = () => {
    return (
      <FormEvaluation
        sections={sections}
        answers={answers}
        desvios={desvForms}
        setAnswers={setAnswers}
        onClickRow={() => {}}
        clickOutlier={() => {}}
        offCollapse
        forbiden="Avaliação assinada por motorista e/ou avaliador não pode ser modificada"
      />
    );
  };

  const formatDate = date => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy');
    }
    return '';
  };

  // Modifique o componente de imagem para usar loading eager
  const renderImages = () => {
    if (!avaliacao?.imagens) return null;

    const imgs = avaliacao.imagens;

    if (imgs?.length > 0) {
      const perArray = 4;
      const imgsArray = imgs.reduce((resultArray, item, index) => {
        const idx = Math.floor(index / perArray);
        if (!resultArray[idx]) {
          resultArray[idx] = [];
        }
        resultArray[idx].push(item);
        return resultArray;
      }, []);

      return imgsArray.map((item, idx) => (
        <div key={idx}>
          <div className={idx > 0 ? 'page-break' : ''} />
          <Imagens
            data={item.map(img => ({
              ...img,
              url: img?.url ? clearImageCache(img.url) : '',
              crossOrigin: 'anonymous', // Add this line
              referrerPolicy: 'no-referrer', // Add this line
            }))}
            onImageLoad={handleImageLoad}
            loading="eager"
          />
        </div>
      ));
    }
    return null;
  };

  return (
    <>
      {avaliacao && col1 ? (
        <div>
          <S.ButtonContainer>
            <Button
              disabled={isPrinting || !isReadyToPrint}
              loading={loading}
              onClick={() => {
                try {
                  setLoading(true);
                  clearPageCache(); // Limpa o cache antes de iniciar a impressão
                  handlePrint();
                } catch (error) {
                  console.error('Erro ao gerar PDF:', error);
                } finally {
                  setLoading(false);
                }
              }}
              style={{
                color: theme.palette.words.button.contrast,
                marginLeft: '50px',
              }}
              variant="contained"
              color="primary"
              startIcon={
                <Icon
                  name="download-outline"
                  color={theme.palette.words.button.contrast}
                />
              }
            >
              {getLoadingText()}
            </Button>
            {loading ? (
              <h1
                style={{
                  color: theme.palette.brand.primary.natural,
                  fontSize: '20px',
                }}
              >
                Download do PDF sendo realizado...
              </h1>
            ) : (
              <div />
            )}
            <Button
              startIcon={
                <Icon
                  name="corner-up-left-outline"
                  color={theme.palette.words.button.contrast}
                />
              }
              onClick={() => navigate(-1)}
              variant="outlined"
              style={{
                borderColor: theme.palette.brand.primary.light,
                color: theme.palette.words.button.contrast,
                marginRight: '50px',
              }}
            >
              Voltar
            </Button>
          </S.ButtonContainer>
          <S.Page ref={contentRef} id="wrapper">
            <S.Wrapper id="main" className="main">
              <S.Header>
                <img src={LogoOnisys} />
                <div>
                  <p>Avaliação do Motorista - Condução do CT</p>
                  <span>{`ID ${id}`}</span>
                </div>
              </S.Header>
              <S.TitleDiv>
                <S.Title>
                  <img src={Volante} />
                  <h1>Informações gerais</h1>
                </S.Title>
              </S.TitleDiv>
              <S.InfoHeader>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <S.Logo
                      src={
                        avaliacao.distribuidora.logo_url
                          ? avaliacao.distribuidora.logo_url
                          : null
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div>
                      <S.Line>
                        <h2>Motorista</h2>
                        <h3>{avaliacao.motorista.nome}</h3>
                      </S.Line>
                      <S.Line>
                        <h2>Avaliador</h2>
                        <h3>
                          {avaliacao.avaliador && avaliacao.avaliador.nome
                            ? avaliacao.avaliador.nome
                            : ''}
                        </h3>
                      </S.Line>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div>
                      <S.Line>
                        <h2>Pontuação</h2>
                        <h3>{`${avaliacao.pontuacao} pts`}</h3>
                      </S.Line>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div>
                      <S.Line>
                        <h2>Status</h2>
                        <h3>{avaliacao.status}</h3>
                      </S.Line>
                    </div>
                  </Grid>
                </Grid>
              </S.InfoHeader>
              <S.Card>
                <S.CardHeader>
                  <h1>Informações da Viagem</h1>
                </S.CardHeader>
                <S.CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {col1.map(({ label, value }, idx) => (
                        <S.CardCol key={idx}>
                          <S.CardContentCol style={{ flex: 1 }}>
                            <h4>{label}</h4>
                          </S.CardContentCol>
                          <S.CardContentCol style={{ flex: 3 }}>
                            <h5>{value}</h5>
                          </S.CardContentCol>
                        </S.CardCol>
                      ))}
                    </Grid>
                    <Grid item xs={6}>
                      {col2.map(({ label, value }, idx) => (
                        <S.CardCol key={idx}>
                          <S.CardContentCol style={{ flex: 1 }}>
                            <h4>{label}</h4>
                          </S.CardContentCol>
                          <S.CardContentCol style={{ flex: 3 }}>
                            <h5>{value}</h5>
                          </S.CardContentCol>
                        </S.CardCol>
                      ))}
                    </Grid>
                  </Grid>
                </S.CardContent>
              </S.Card>
              <S.Card>
                <S.CardHeader>
                  <h1>Resultado da Avaliação</h1>
                </S.CardHeader>
                <S.CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <S.CardCol>
                        <S.CardContentCol style={{ flex: '0.3 !important' }}>
                          <h4>Observação</h4>
                        </S.CardContentCol>
                        <S.CardContentCol style={{ flex: '1 !important' }}>
                          <h5>{avaliacao.observacoes}</h5>
                        </S.CardContentCol>
                      </S.CardCol>
                    </Grid>
                  </Grid>
                </S.CardContent>
              </S.Card>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: '10px',
                }}
              >
                {col3.map(({ label, value }, idx) => (
                  <Grid item xs={4} key={idx}>
                    <S.Card>
                      <S.CardItem>
                        <p style={{ fontSize: '12px', width: '145px' }}>
                          {label}
                        </p>
                        <span style={{ fontSize: '10px' }}>{value}</span>
                      </S.CardItem>
                    </S.Card>
                  </Grid>
                ))}
              </Grid>
              <S.Card>
                <S.CardHeader>
                  <h1>Aceite a Avaliação</h1>
                </S.CardHeader>
                <S.CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <S.Assinatura>
                        <S.AsignTitle>
                          {avaliacao.avaliador ? (
                            avaliacao.avaliador.nome.toLowerCase()
                          ) : (
                            <S.NoContent>Aguardando Assinatura</S.NoContent>
                          )}
                        </S.AsignTitle>
                        <S.AsignDate>
                          {formatDate(avaliacao.data_finalizacao)}
                        </S.AsignDate>
                        <hr />
                        <h4>Avaliador</h4>
                      </S.Assinatura>
                    </Grid>
                    <Grid item xs={4}>
                      <S.Assinatura>
                        <S.AsignTitle>
                          {avaliacao.testemunha
                            ? avaliacao.testemunha.nome.toLowerCase()
                            : 'Aguardando Assinatura'}
                        </S.AsignTitle>
                        <S.AsignDate>
                          {avaliacao.assinatura_testemunha_em
                            ? formatDate(avaliacao.assinatura_testemunha_em)
                            : ''}
                        </S.AsignDate>
                        <hr />
                        <h4>Testemunha</h4>
                      </S.Assinatura>
                    </Grid>
                    <Grid item xs={4}>
                      <S.Assinatura>
                        <S.AsignTitle>
                          {avaliacao.status == 'ASSINADA'
                            ? avaliacao.motorista.nome.toLowerCase()
                            : ''}
                        </S.AsignTitle>
                        <S.AsignDate>
                          {avaliacao.assinada_em ? (
                            formatDate(avaliacao.assinada_em)
                          ) : (
                            <S.NoContent>Aguardando Assinatura</S.NoContent>
                          )}
                        </S.AsignDate>
                        <hr />
                        <h4>Motorista</h4>
                      </S.Assinatura>
                    </Grid>
                  </Grid>
                </S.CardContent>
              </S.Card>
            </S.Wrapper>
            <>
              <div className="page-break" />
              <S.Wrapper id="main">
                <S.Header>
                  <img src={LogoOnisys} />
                  <div>
                    <p>Avaliação do Motorista - Condução do CT</p>
                    <span>{`ID ${id}`}</span>
                  </div>
                </S.Header>
                <S.TitleDiv>
                  <S.Title>
                    <img src={Volante} />
                    <h1>FORMULÁRIO DE AVALIAÇÃO</h1>
                  </S.Title>
                </S.TitleDiv>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: 10,
                  }}
                >
                  <p>
                    * Perguntas respondidas automaticamente pela plataforma
                    Onisys
                  </p>
                  <p>
                    Preenchimento: SIM - Item Atendido; NÃO - Item Não Atendido;
                    NA - Não Avaliado ou Não Aplicável
                  </p>
                </div>
                {!loading && answers && sections && renderAnswers()}
              </S.Wrapper>
            </>

            {desvios && (
              <>
                <div className="page-break" />

                <S.Wrapper id="main" className="main">
                  <S.Header>
                    <img
                      src={
                        avaliacao.distribuidora.logo_url
                          ? avaliacao.distribuidora.logo_url
                          : null
                      }
                    />
                    <div>
                      <p>Avaliação do Motorista - Condução do CT</p>
                      <span>{`ID ${id}`}</span>
                    </div>
                  </S.Header>
                  <S.TitleDiv>
                    <S.Title>
                      <img src={Volante} />
                      <h1>Desvios da Avaliação</h1>
                    </S.Title>
                  </S.TitleDiv>
                  {desvios.map((data, idx) => (
                    <Desvio data={data} key={idx} />
                  ))}
                </S.Wrapper>
              </>
            )}
            {avaliacao.imagens.length > 0 && (
              <>
                <div className="page-break" />
                <S.Wrapper id="main" className="main">
                  <S.Header>
                    <img
                      src={
                        avaliacao.distribuidora.logo_url
                          ? avaliacao.distribuidora.logo_url
                          : ''
                      }
                    />
                    <div>
                      <p>Avaliação do Motorista - Condução do CT</p>
                      <span>{`ID ${id}`}</span>
                    </div>
                  </S.Header>
                  <S.TitleDiv>
                    <S.Title>
                      <img src={Volante} />
                      <h1>Imagens da Avaliação</h1>
                    </S.Title>
                  </S.TitleDiv>
                  {renderImages()}
                </S.Wrapper>
              </>
            )}
          </S.Page>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ModelPdf;
