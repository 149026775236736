import api from 'services/api';
import { iconsCards } from './constants';

export const requestDesvios = async query => {
  const res = await api.get('/desvios', { params: query });
  return res;
};

export const getDeslocamentos = async query => {
  const res = await api.get('/desvios/deslocamentos', { params: query });
  return res?.data || [];
};

export const getDeslocamento = async id => {
  const res = await api.get(`/desvios/deslocamentos/${id}`);
  return res?.data;
};

export const fetchDesvio = async id => {
  const res = await api.get(`/desvios/${id}`);
  if (res?.data?.data) return res.data.data;
  if (res?.data?.message) throw new Error(res?.data?.message);
  throw new Error('Erro interno do servidor');
};

export const fetchSequence = async (index, query) => {
  const res = await api.get(`/desvios/paginator/${index}`, { params: query });
  if (res?.data) return res.data;
  return null;
};

export const requestCards = async query => {
  const res = await api.get('/desvios/cards', { params: query });
  if (res?.data?.data)
    return res.data.data.map(card => ({
      ...card,
      icon: iconsCards[card.type],
    }));
  return [];
};

export const requestCount = async query => {
  const res = await api.get('/desvios/paginator', { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/desvios', { params: query });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const aprovarDesvios = async data => {
  const res = await api.put(`/desvios/aprovar`, data);
  return res;
};

export const salvarDesvio = async (ids, data) => {
  const res = await api.put(`/desvios`, { ...data, ids });
  return res;
};

export const excluirDesvio = async (ids, data) => {
  const res = await api.put(`/desvios/delete`, { ...data, ids });
  return res;
};

export const atribuirResponsavel = async data => {
  const res = await api.put(`/desvios/responsavel`, data);
  return res;
};

export const finalizarDesvios = async data => {
  const res = await api.put(`/desvios/finalizar`, data);
  return res;
};

export const analisarDesvios = async (id, data) => {
  const res = await api.put(`/desvios/analise/${id}`, data);
  return res;
};

export const retornarDesvios = async (id, data) => {
  const res = await api.put(`/desvios/retorno/${id}`, data);
  return res;
};

export const revisaoExclusaoProvider = async data => {
  // 'aprovar' || 'recusar' || 'solicitar-evidencias'
  const event = data.event;
  const rota = `/desvios/revisao/${event}`;
  const res = await api.put(rota, data);
  return res;
};

export const tratarDesvios = async data => {
  const res = await api.post('/desvios/deslocamentos/tratativa', data);
  return res?.data;
};

export const getResponsaveis = async id => {
  const res = await api.get(`/desvios/${id}/responsaveis`);
  return res?.data?.data || [];
};

export const getResponsaveisDesvio = async id => {
  const res = await api.get(`/desvios/responsibles-desvios-select`);
  return res?.data?.rows || [];
};

export const requestNiveis = async () => {
  const res = await api.get(`/niveis-usuarios`);
  return res.data?.data ?? [];
};
