import React, { useEffect, useState, useRef, useMemo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { Background } from 'components/Inputs/DropZoneCustom/EditFileModal/styled';

/*
data: lista de objetos com
    {
        id: valor do id correspondente,
        value: string do nome do objeto selecionado
    }

fieldName: nome da variável armazenada. Substitui a necessidade de:
    > handleChange={(e) => handleChange(e.target.value, 'name')}
    por:
        > handleChange={handleChange}
        > fieldName: 'name'

value: array com ids selecionados. Ex: '[1, 3]'

*/
// Debounce utility function
function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
}

const SelectMultiple = ({
  value,
  handleChange,
  data,
  fieldName,
  label,
  selectAll,
  disabled,
  required = false,
  placeholder,
  error,
  message,
  shouldOpenSelect,
  setShouldOpenSelect,
}) => {
  const selectRef = useRef(null);
  const theme = useTheme();
  const [backupItems, setBackupItems] = useState([]);
  const [search, setSearch] = useState('');
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    setIsSelectOpen(shouldOpenSelect);
  }, [shouldOpenSelect]);

  useEffect(() => {
    setBackupItems(data);
  }, [data]);

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
    if (setShouldOpenSelect) {
      setShouldOpenSelect(true);
    }
  };

  const handleSelectClose = () => {
    // Não fechar o select se o clique foi na caixa de pesquisa
    if (document.activeElement?.closest('.search-box')) {
      return;
    }
    setIsSelectOpen(false);
    if (setShouldOpenSelect) {
      setShouldOpenSelect(false);
    }
  };

  const handleSelectSelect = e => {
    handleChange(e.target.value);
    // Não fechar o select automaticamente após a seleção
    // setShouldOpenSelect(false);
    // setIsSelectOpen(false);
  };

  const handleSearchChange = e => {
    e.stopPropagation(); // Previne a propagação do evento
    setSearch(e.target.value.toLowerCase());
  };

  const filteredData = useMemo(() => {
    if (!search) return data;
    return data.filter(item => item.label.toLowerCase().includes(search));
  }, [data, search]);

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        {label && (
          <S.Label>
            {label}
            {required && <S.Required>*</S.Required>}
          </S.Label>
        )}
        {placeholder && (
          <InputLabel sx={{ zIndex: 0 }} id="ba" shrink>
            {placeholder}
          </InputLabel>
        )}
        <Select
          id="ba"
          error={error}
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          open={isSelectOpen}
          value={value}
          onChange={handleSelectSelect}
          input={<S.Input />}
          disabled={disabled}
        >
          <S.SearchBox
            className="search-box"
            onClick={e => e.stopPropagation()} // Previne que o clique na busca feche o select
          >
            <S.SearchInput
              type="text"
              placeholder="Buscar..."
              onChange={handleSearchChange}
              onKeyDown={e => e.stopPropagation()} // Previne que o teclado feche o select
              value={search}
            />
            <SearchOutlinedIcon
              className="searchIcon"
              htmlColor={theme.palette.semantics.feedback.unknown.natural}
            />
          </S.SearchBox>

          {filteredData.length > 0 &&
            filteredData.map(item => (
              <S.Item key={item.value} value={item.value} color={item.color}>
                <div className="status">{item.criticality}</div>
                {item.label}
              </S.Item>
            ))}
          {filteredData.length === 0 && (
            <S.EmptyOptions>Nenhuma opção disponível</S.EmptyOptions>
          )}
        </Select>
        {message && (
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.dark,
              margin: '10px 0',
            }}
          >
            {message}
          </span>
        )}
      </FormControl>
    </div>
  );
};

export default SelectMultiple;
