import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Ranking from 'pages/_templates/Ranking';
import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';
import { requestRanking, requestResumo } from './services';
import { categorias, columns, fields, getExcelFields } from './constants';
import { getRouterQueryParams } from 'utils/router-query-params';
import ExcelModal from 'components/ExcelModalNew';
import { generateFileName } from 'utils/generateFileName';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const RankingEmpresas = () => {
  const navigate = useNavigate();
  const { planosAtivos, isOpLogistico, isProvider } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterRanking = useSelector(
    state => state.filterEmpresasRankingProvider,
  );
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.ranking;
  });

  const [tab, setTab] = useState('geral');
  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [podium, setPodium] = useState([]);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const selects = useSelector(state => state.selects);

  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const { motoristasTipos, veiculos, operacao, desviosTiposPadrao } =
    useFetchMultipleWithCache();

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const {
    isFetching,
    isLoading,
    data: resData,
    refetch: refetchDataRanking,
  } = useQuery(
    ['ranking', query, tab],
    () => query && requestRanking({ ...query, tipo: tab }),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const podium = res?.data?.podio || null;
        if (podium)
          setPodium(
            podium.map(item => ({
              nameOne: item.nome,
              position: item.posicao,
              oldPosition: item.posicao_anterior
                ? item.posicao_anterior > item.posicao
                  ? 'UP'
                  : item.posicao_anterior < item.posicao
                  ? 'DOWN'
                  : 'EQUAL'
                : 'UP',
              logo: item.foto,
              value: item.valor,
            })),
          );
      },
      onSettled: () => {
        setLoadingTab(false);
      },
    },
  );

  const {
    isFetching: isFetchingResumo,
    isLoading: isLoadingResumo,
    data: resResumo,
    refetch: refetchDataResumeRanking,
  } = useQuery(
    ['ranking-resumo', query],
    () => query && requestResumo({ ...query, tipo: tab }),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        setLoadingTab(false);
      },
    },
  );

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    // setSelectedCard(null);
    setLoadingTab(true);
    setTab(newValue);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: tab,
    items: categorias.filter(
      item =>
        !item.plan ||
        planosAtivos.some(plano => plano.id_do_plano === item.plan),
    ),
    onChange: handleStatusTab,
    disabled: isLoading,
  };

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    }
    setQuery({
      ...query,
      ...filter,
      empresas: filter.empresas ? filter.empresas.split(',') : '',
      ...filterRanking,
      desvio: filterRanking.desvio ? filterRanking.desvio.split(',') : '',
      tipo: tab,
      sortBy: { id: 'posicao', order: 'ASC', desc: false },
    });
  }, [filter, filterRanking, tab]);

  // ------------------------------ EXCEL --------------------------------//
  // const handleExportar = useCallback(
  //   async query => {
  //     trackEvent(user, 'EXPORTAR RANKING');
  //     setLoadingExcel(true);

  //     const newFields = getExcelFields(query.tipo).filter(
  //       item =>
  //         !item.plans ||
  //         planosAtivos.some(({ id_do_plano }) =>
  //           item.plans.includes(id_do_plano),
  //         ),
  //     );
  //     const formatedDate = formatNameDate(new Date());
  //     const newQuery = { ...query, excelFields: newFields };

  //     const res = await requestExcel(newQuery);
  //     if (res.excel) {
  //       ExportToExcel({
  //         excel: res.excel,
  //         name: `ranking_empresas_${formatedDate}`,
  //       });
  //       toast.success(res.message);
  //     } else if (res.message) toast.error(res.message);

  //     setLoadingExcel(false);
  //   },
  //   [planosAtivos],
  // );

  // ------------------------------ Columns --------------------------------//
  const formattedColumns = useMemo(
    () =>
      columns[tab]
        ?.filter(
          col =>
            !col.plans ||
            planosAtivos.some(({ id_do_plano }) =>
              col.plans.includes(id_do_plano),
            ),
        )
        .map(item => {
          console.log('Condição :', query?.empresas?.length);
          return query?.empresas?.length === 1 && item.id === 'nome_empresa'
            ? {
                ...item,
                header: 'Filial',
                subRow: {
                  prefix: 'Filial:',
                  value: (_, item) => item?.nome_filial,
                },
              }
            : item;
        }),
    [tab, query],
  );

  const actions = [
    {
      title: 'Ver perfil',
      function: (id, item) => {
        if (item.id_empresa) navigate(`/empresas/${item.id_empresa}`);
        else toast.error('Não foi possível acessar os detalhes desta empresa.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    return fields[tab]
      .filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        const data = resResumo?.data?.data[item.id] ?? '';
        return {
          ...item,
          value: item.value ? item.value(data) : data || '',
        };
      });
  }, [resResumo, fields, tab]);

  let fieldsProvider = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresas,
    },
    {
      filterName: 'desvio',
      label: 'Filtrar por desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'modorista',
      label: 'Motorista',
      options: motoristasTipos,
      multiple: false,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
      multiple: false,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
      multiple: false,
    },
  ];

  return (
    <>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={getExcelFields(query.tipo).filter(
            item =>
              !item.plans ||
              planosAtivos.some(({ id_do_plano }) =>
                item.plans.includes(id_do_plano),
              ),
          )}
          query={{
            ...query,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route="/excel-provider/empresas_ranking"
          filtersPersis={filtersPersist}
          collumns={fieldsProvider}
          isManualDownload
        />
      )}
      <Ranking
        title="Ranking"
        headerInfo=""
        handleFilters={val => setQuery({ ...query, ...val })}
        headerActions={() => {
          trackEvent(user, 'Exportar Planilha Ranking Motoristas');
          setOpenExcelModal(true);
        }}
        headerTabs={headerTabs}
        handleRefetch={() => {
          refetchDataRanking();
          refetchDataResumeRanking();
        }}
        podium={podium}
        loading={isFetching}
        tableProps={{
          loading: isLoading || loadingTab,
          data: resData?.data?.data ?? [],
          columns: formattedColumns,
          actions,
          searchKeys: ['nome'],
          placeholder: 'Buscar por Empresa',
          sortBy: { id: 'posicao', order: order ?? 'ASC' },
          searchEvent: search =>
            trackEvent(user, 'Busca Ranking de Empresas', null, search),
          empty: {
            image: 'motorista.png',
            title: 'Ops! Não foram encontradas empresas para essa busca.',
            description:
              'Altere os filtros ou faça o cadastro do novo motorista!',
          },
        }}
        resumo={formatResumo}
      />
    </>
  );
};

export default RankingEmpresas;
