import React from 'react';

import * as S from './styled';

const BarChartVertical = ({ data = [], loading = false, meta = null }) => {
  const colors = {
    PRIMÁRIA: '#00bcd4',
    MODERADA: '#0000FF',
    GRAVE: '#ffa726',
    GRAVÍSSIMA: '#e57373',
  };

  return (
    <S.ChartContainer>
      {meta && <S.Meta>Meta: {`${meta}%`}</S.Meta>}
      <S.BarChart>
        {data.map((item, index) => (
          <S.ContainerBarChart key={item.name}>
            <S.Label color={colors[item.name]}>{item.name}</S.Label>
            <S.BarContainer>
              <S.Bar value={item.value} color={colors[item.name]} />
              <S.Value color={colors[item.name]}>{item.value}%</S.Value>
            </S.BarContainer>
          </S.ContainerBarChart>
        ))}
      </S.BarChart>
    </S.ChartContainer>
  );
};
export default BarChartVertical;
