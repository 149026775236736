import { formatNewDate, formatTime } from 'utils/dates';

export const columnsDefault = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Empresa/Filial',
    id: 'empresa.nome',
    type: 'string',
    tooltip: true,
    sort: false,
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item?.motorista?.filial?.nome,
    },
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    width: 100,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Desvio',
    id: 'desvio_tipo.titulo',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'desvio_tipo.criticidade',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA' || value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_desvio),
    subRow: {
      prefix: 'Horário:',
      value: (_, item) => formatTime(item?.data_desvio, true),
    },
  },
];

export const columnsPending2 = [
  ...columnsDefault,
  {
    header: 'Solicitação de Exclusão',
    id: 'data_exclusao',
    type: 'string',
    sort: true,
    align: 'center',
    width: 230,
    value: value => formatNewDate(value),
  },
  {
    header: 'Prazo',
    id: 'data_exclusao',
    type: 'string',
    sort: false,
    align: 'center',
    width: 150,
    value: (value, _) => {
      const date = new Date(value);
      date.setDate(date.getDate() + 5);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
];

export const columnsRevaluation2 = [
  ...columnsDefault,
  {
    header: 'Enviado Reavaliação',
    id: 'data_revisao_distribuidora',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_revisao_distribuidora),
  },
  {
    header: 'Prazo',
    id: 'data_revisao_distribuidora',
    type: 'string',
    sort: false,
    width: 100,
    value: (value, _) => {
      const date = new Date(value);
      date.setDate(date.getDate() + 5);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
];

export const columnsApproved2 = [
  ...columnsDefault,
  {
    header: 'Aprovado Em',
    id: 'data_revisao_distribuidora',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_revisao_distribuidora),
  },
  {
    header: 'Responsável',
    id: 'responsavel.nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
];

export const columnsRejected2 = [
  ...columnsDefault,
  {
    header: 'Rejeitado Em',
    id: 'data_revisao_distribuidora',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_revisao_distribuidora),
  },
  {
    header: 'Responsável',
    id: 'responsavel.nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
];
