import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import * as S from './styled';
import * as API from '../services';
import Skeleton from '@mui/material/Skeleton';

const CustomTick = props => {
  const { x, y, payload } = props;
  const [month, year] = payload.value.split(' ');

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        fontSize={11}
        dy={12}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        {month}
        <tspan x={0} dy="15">
          {year}
        </tspan>
      </text>
    </g>
  );
};

export const GraficoFatorDeRisco = ({ filters }) => {
  const [allFatores, setAllFatores] = useState([]);
  const [legendFactor, setLegendFactor] = useState(null);

  const [loadingFatorRisco, setLoadingFatorRisco] = useState(false);

  const [visible, setVisible] = useState({});

  const toggleVisibility = factor => {
    setVisible(v => ({ ...v, [factor]: !v[factor] }));
  };

  const fetchFatoresDeRisco = async () => {
    setLoadingFatorRisco(true);
    const res = await API.getFatoresRisco({ ...filters });
    setAllFatores(res?.data?.periodValues || []);

    const factors = {
      factor1: res?.data?.factor1,
      factor2: res?.data?.factor2,
      factor3: res?.data?.factor3,
      factor4: res?.data?.factor4,
    };

    setLegendFactor(factors);

    // Atualiza o visible apenas com os fatores que existem
    setVisible(
      Object.keys(factors).reduce((acc, key) => {
        if (factors[key]) {
          acc[key.charAt(0).toUpperCase() + key.slice(1)] = true;
        }
        return acc;
      }, {}),
    );

    setLoadingFatorRisco(false);
  };

  useEffect(() => {
    fetchFatoresDeRisco(filters);
  }, [filters]);

  return (
    <S.Container>
      {!loadingFatorRisco ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={allFatores}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="period"
              height={70}
              tick={<CustomTick />}
              interval={0}
            />
            <YAxis />

            {legendFactor?.factor1 && visible.Factor1 && (
              <Line
                type="linear"
                dataKey="factor1"
                name={legendFactor.factor1}
                stroke="#f64e60"
                dot={props => {
                  const { index, payload } = props;
                  const isLastDot = index === allFatores.length - 1; // Verifica se é o último ponto
                  return (
                    <circle
                      cx={props.cx}
                      cy={props.cy}
                      r={5}
                      fill={isLastDot ? 'none' : '#f64e60'} // Se for o último ponto, não preenche
                      stroke={isLastDot ? '#f64e60' : 'none'} // Se for o último ponto, define a cor da borda
                      strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                    />
                  );
                }}
              />
            )}
            {legendFactor?.factor2 && visible.Factor2 && (
              <Line
                type="linear"
                dataKey="factor2"
                name={legendFactor.factor2}
                stroke="#FFA801"
                dot={props => {
                  const { index, payload } = props;
                  const isLastDot = index === allFatores.length - 1; // Verifica se é o último ponto
                  return (
                    <circle
                      cx={props.cx}
                      cy={props.cy}
                      r={5}
                      fill={isLastDot ? 'none' : '#FFA801'}
                      stroke={isLastDot ? '#FFA801' : 'none'}
                      strokeWidth={isLastDot ? 2 : 0}
                    />
                  );
                }}
              />
            )}
            {legendFactor?.factor3 && visible.Factor3 && (
              <Line
                type="linear"
                dataKey="factor3"
                name={legendFactor.factor3}
                stroke="#0C12F2"
                dot={props => {
                  const { index, payload } = props;
                  const isLastDot = index === allFatores.length - 1;
                  return (
                    <circle
                      cx={props.cx}
                      cy={props.cy}
                      r={5}
                      fill={isLastDot ? 'none' : '#0C12F2'} // Se for o último ponto, não preenche
                      stroke={isLastDot ? '#0C12F2' : 'none'} // Se for o último ponto, define a cor da borda
                      strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                    />
                  );
                }}
              />
            )}
            {legendFactor?.factor4 && visible.Factor4 && (
              <Line
                type="linear"
                dataKey="factor4"
                name={legendFactor.factor4}
                stroke="#1BC5BD"
                dot={props => {
                  const { index, payload } = props;
                  const isLastDot = index === allFatores.length - 1;
                  return (
                    <circle
                      cx={props.cx}
                      cy={props.cy}
                      r={5}
                      fill={isLastDot ? 'none' : '#1BC5BD'}
                      stroke={isLastDot ? '#1BC5BD' : 'none'}
                      strokeWidth={isLastDot ? 2 : 0}
                    />
                  );
                }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton
          width="100%"
          animation="wave"
          opacity={0.5}
          height={200}
          style={{
            transform: 'translateY(-0px)',
            marginLeft: 50,
            marginRight: 10,
          }}
        />
      )}
      <div
        style={{
          marginTop: 20,

          fontSize: 14,
          flexDirection: 'column',
        }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {legendFactor?.factor1 && (
              <FormControlLabel
                checked={visible.Factor1}
                onClick={() => toggleVisibility('Factor1')}
                control={
                  <Radio
                    sx={{
                      color: '#f64e60',
                      '&.Mui-checked': {
                        color: '#f64e60',
                      },
                    }}
                  />
                }
                sx={{
                  opacity: visible.Factor1 ? 1 : 0.5,
                  '& .MuiFormControlLabel-label': {
                    opacity: visible.Factor1 ? 1 : 0.5,
                    color: '#4B5166',
                  },
                }}
                label={legendFactor.factor1}
                name="Factor1"
              />
            )}
            {legendFactor?.factor2 && (
              <FormControlLabel
                checked={visible.Factor2}
                onClick={() => toggleVisibility('Factor2')}
                control={
                  <Radio
                    sx={{
                      color: '#FFA801',
                      '&.Mui-checked': {
                        color: '#FFA801',
                      },
                    }}
                  />
                }
                sx={{
                  opacity: visible.Factor2 ? 1 : 0.5,
                  '& .MuiFormControlLabel-label': {
                    opacity: visible.Factor2 ? 1 : 0.5,
                    color: '#4B5166',
                  },
                }}
                label={legendFactor?.factor2}
                name="Factor2"
              />
            )}
            {legendFactor?.factor3 && (
              <FormControlLabel
                checked={visible.Factor3}
                onClick={() => toggleVisibility('Factor3')}
                control={
                  <Radio
                    sx={{
                      color: '#0C12F2',
                      '&.Mui-checked': {
                        color: '#0C12F2',
                      },
                    }}
                  />
                }
                sx={{
                  opacity: visible.Factor3 ? 1 : 0.5,
                  '& .MuiFormControlLabel-label': {
                    opacity: visible.Factor3 ? 1 : 0.5,
                    color: '#4B5166',
                  },
                }}
                label={legendFactor?.factor3}
                name="Factor3"
              />
            )}
            {legendFactor?.factor4 && (
              <FormControlLabel
                checked={visible.Factor4}
                onClick={() => toggleVisibility('Factor4')}
                control={
                  <Radio
                    sx={{
                      color: '#1BC5BD',
                      '&.Mui-checked': {
                        color: '#1BC5BD',
                      },
                    }}
                  />
                }
                label={legendFactor?.factor4}
                name="Factor4"
                sx={{
                  opacity: visible.Factor4 ? 1 : 0.5,
                  '& .MuiFormControlLabel-label': {
                    opacity: visible.Factor4 ? 1 : 0.5,
                    color: '#4B5166',
                  },
                }}
              />
            )}
          </RadioGroup>
        </FormControl>
      </div>
    </S.Container>
  );
};
