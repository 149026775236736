import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4b5166;
  border-top: 1px solid #939aab4d;
  padding: 0.5rem 0;

  .text {
    width: 100%;
    display: flex;
    gap: ${({ first }) => (first ? '0' : '0.5rem')};
    justify-content: ${({ first }) => (first ? 'center' : 'space-between')};
    align-items: ${({ first }) => (first ? 'start' : 'center')};
    flex-direction: ${({ first }) => (first ? 'column' : 'row')};
  }

  .name {
    width: 100%;
    max-width: ${({ first }) => (first ? '10vw' : '20vw')};
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: normal normal medium 16px/20px Texta;
  }

  .desvios {
    text-align: ${({ first }) => (first ? 'left' : 'right')};
    font-size: 16px;
    font-style: medium;
    letter-spacing: 0px;
    color: #ff8040;
    white-space: nowrap;
  }
`;
